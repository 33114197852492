import React, { useState, useRef, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { services } from "../services/api";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import swal from "sweetalert";
import SelectBusqueda from "../components/SelectConBusqueda";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LaunchIcon from "@mui/icons-material/Launch";
import { FormControl } from "@mui/material";
import GifLoader from "../components/ui/gifLoader";
import { NotificacionContext } from "../context/notificacion_context";

const ModalCertificadoCedula = ({ data, open, close, closeandRefresh }) => {
  const [rechazado, setRechazado] = useState(true);
  const { showNotification } = useContext(NotificacionContext);
  const handleRechazado = () => {
    setRechazado(false);
    setBotones(true);
    setForm({ ...form, estatus: 5 });
  };
  const [aceptado, setAceptado] = useState(true);
  const handleAceptado = () => {
    setAceptado(false);
    setBotones(true);
    setForm({ ...form, estatus: 2 });
  };
  const [botones, setBotones] = useState(false);
  const aceptadoRef = useRef(null);
  const [instituciones, setInstituciones] = useState([]);
  const rolUser = localStorage.getItem("rol");
  const colorUI = "#6f1d46";
  const [qrData, setQRData] = useState("");
  const [loading, setLoading] = useState(false);

  // const [fecha_exp, setFechaExp] = useState(dayjs(new Date()));

  const [form, setForm] = useState({
    selectOpcion: "",
    folio: "",
    estatus: "",
    enviar_email: 1,
    clave_profesion: "",
    observaciones: "",
    estado: "",
    claveInstitucion: "", // para el select  de instituciones
    clave_Institucion: "", //para el Text Field
    nombreInstitucion: "",
  });

  const [observations, setObservations] = useState({
    observaciones: "",
    folio: "",
  });

  const loadObservations = () => {
    setObservations({ ...observations, observaciones: data.observaciones });
  };

  const [mostrarCamposInstitucion, setMostrarCamposInstitucion] =
    useState(false);

  const handleCheckboxChange = (event) => {
    setMostrarCamposInstitucion(event.target.checked);
    setForm({
      ...form,
      estado: "",
      clave_Institucion: "", //para el Text Field
      nombreInstitucion: "",
    });

    if (event.target.checked) {
      setForm({
        ...form,
        claveInstitucion: "",
        selectOpcion: "",
      });
    }
  };

  const handleCheckboxEmail = (event) => {
    setForm({
      ...form,
      enviar_email: event.target.checked ? 1 : 0,
    });
  };

  const rechazadoRef = useRef(null);

  const obtenerCatalogos = async (e) => {
    // const dataProfesiones = await services("POST", "catalogos/profesiones", {})
    const dataInstitusiones = await services(
      "POST",
      "catalogos/instituciones",
      {}
    );
    // setProfesiones({
    //     ...profesiones,
    //     profesion: dataProfesiones.data[0]
    // })
    setInstituciones({
      ...instituciones,
      instituciones: dataInstitusiones.data,
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      obtenerCatalogos();
    }
  }, []);

  const [datos, setDatos] = useState({});
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflow: "hidden",
    overflowY: "scroll",
    p: 4,
  };

  const obtDatos = async () => {
    setForm({ ...form, folio: data.folio });
    setDatos(data);
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#005cbf"),
    backgroundColor: "#6f1d46",
    // backgroundColor: '#4a001f',
    "&:hover": {
      backgroundColor: '#8d3461"',
      // backgroundColor: '#4a001f',
    },
  }));
  const handleChange = (e) => {
    let { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleChangeInstitucion = (e) => {
    setForm({
      ...form,
      selectOpcion: e,
      institucion_educativa: e.id,
      claveInstitucion: e.cct,
    });
  };

  const handleNombreInstitucion = (e) => {
    setForm({
      ...form,
      nombreInstitucion: e.target.value,
    });
  };
  const handleEstado = (e) => {
    setForm({
      ...form,
      estado: e.target.value,
    });
  };
  const handleClaveInstitucion = (e) => {
    setForm({
      ...form,
      clave_Institucion: e.target.value,
    });
  };

  // Aceptar tramite director
  const saveDirector = async () => {
    swal({
      title: "¡Atención!",
      text: "¿Estás seguro de realizar esta acción?",
      icon: "warning",
      buttons: ["Cancelar", "Continuar"],
      confirmButton: true,
      className: "alertFormularioEncontrado",
    }).then(async (aceptar) => {
      if (aceptar) {
        setLoading(true);
        const validacion = {
          folio: form.folio,
          // enviar 4 para aceptar tramite
          estatus: 4,
        };

        const dataDatos = await services(
          "POST",
          "see/profesiones/validarRegistroTitulo",
          validacion
        );
        // console.log(dataDatos, "dataDatos");
        if (dataDatos.status === 200) {
          setLoading(false);
          setQRData(dataDatos.data.qr);
          // setModalOpen(true);
          swal({
            title: "¡Exito!",
            text: "Solicitud aceptada con éxito",
            icon: "success",
            button: "Aceptar",
          }).then(() => {
            closeandRefresh();
          });
          document.querySelector(
            ".swal-button--confirm"
          ).style.backgroundColor = colorUI;
        } else {
          swal({
            title: "¡Error!",
            text: dataDatos.error,
            icon: "error",
          });
          document.querySelector(
            ".swal-button--confirm"
          ).style.backgroundColor = colorUI;
        }
      }
    });
    document.querySelector(".swal-button--confirm").style.backgroundColor =
      colorUI;
  };

  const handlePrintQR = () => {
    downloadPDF(qrData, "QR", null, "pdf");
  };

  // Enviar observaciones
  const sendObservations = async (folioObservation) => {
    setObservations((prevObservations) => {
      const updatedObservations = {
        ...prevObservations,
        folio: folioObservation,
      };

      services(
        "POST",
        "see/profezionalizacion/anexo",
        updatedObservations
      ).then(({ data, status }) => {
        if (status === 404) {
          showNotification(
            "error",
            "Acción No Exitosa!",
            "La operación no se realizó correctamente",
            null,
            4
          );
        }
        if (status === 200) {
          swal({
            title: "¡Éxito!",
            text: "Se han enviado las notas con éxito.",
            icon: "success",
            button: "Aceptar",
          }).then(() => {
            closeandRefresh();
          });
        }
        // document.querySelector(".swal-button--confirm").style.backgroundColor =
        //   "#6f1d46";
        loadObservations();
      });

      return updatedObservations;
    });
  };

  const downloadPDF = (documentLink, name, callback, extension) => {
    try {
      const linkSource = `data:application/${extension};base64,${documentLink}`;
      const downloadLink = document.createElement("a");
      const fileName = `${name}.${extension}`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      if (callback !== null) {
        callback();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const guardar2 = async () => {
    if (form.observaciones !== "") {
      const validacion = {
        folio: form.folio,
        estatus: form.estatus,
        enviar_email: form.enviar_email,
        observaciones: form.observaciones,
      };
      const dataDatos = await services(
        "POST",
        "see/profesiones/validarRegistroTitulo",
        validacion
      );

      if (dataDatos.status === 200) {
        swal({
          title: "¡Éxito!",
          text: "Solicitud Actualizada",
          icon: "success",
          button: "Aceptar",
        }).then(() => {
          closeandRefresh();
          if (form.enviar_email === 0) {
            window.open(dataDatos.data.linkModificacion, "_blank");
          }
        });
        document.querySelector(".swal-button--confirm").style.backgroundColor =
          colorUI;
      } else {
        swal({
          title: "¡Error!",
          text: dataDatos.error,
          icon: "error",
        });
      } // window.location.reload(false)
    } else {
      const claveInstitucion = form.claveInstitucion
        ? form.claveInstitucion
        : form.clave_Institucion;

      const validacion = {
        folio: form.folio,
        estatus: form.estatus,
        clave_profesion: form.clave_profesion,
        clave_institucion: claveInstitucion,
        institucion: form.nombreInstitucion,
        entidad: form.estado,
      };

      const institucionText = form.nombreInstitucion !== "";
      const entidadText = form.estado !== "";
      const claveInstitucionSelect = !!form.claveInstitucion;
      const claveInstitucionText = form.clave_Institucion !== "";

      // console.log(form.estatus)

      if (
        claveInstitucionSelect ||
        (institucionText && entidadText && claveInstitucionText)
      ) {
        if (form.estatus === 2) {
          const dataDatos = await services(
            "POST",
            "see/profesiones/validarRegistroTitulo",
            validacion
          );

          // console.log(dataDatos);

          if (dataDatos.status === 200) {
            swal({
              title: "¡Éxito!",
              text: "Solicitud actualizada correctamente.",
              icon: "success",
              button: "Aceptar",
            }).then(() => {
              closeandRefresh();
            });
          } else if (dataDatos.status === 422) {
            swal({
              title: "Cuidado",
              text: dataDatos.data,
              icon: "warning",
            });
          } else {
            swal({
              title: "¡Error!",
              text: "Todos los campos son requeridos",
              icon: "warning",
            });
          }
        } else {
          swal({
            title: "Atención",
            text: "Todos los campos son requeridos",
            icon: "warning",
          });
        }
      } else {
        swal({
          title: "¡Error!",
          text: "Todos los campos son requeridos",
          icon: "warning",
        });
      }
    }
  };

  const guardar = async () => {
    if (
      form.enviar_email !== 0 &&
      form.observaciones === "" &&
      rechazado === false
    ) {
      swal({
        title: "¡Error!",
        text: "El campo observaciones es requerido.",
        icon: "warning",
      });
      return;
    }

    if (form.observaciones !== "" || form.enviar_email === 0) {
      const validacion = {
        folio: form.folio,
        estatus: form.estatus,
        enviar_email: form.enviar_email,
        observaciones: form.observaciones,
      };

      const dataDatos = await services(
        "POST",
        "see/profesiones/validarRegistroTitulo",
        validacion
      );

      if (dataDatos.status === 200) {
        if (form.enviar_email === 1) {
          swal({
            title: "¡Éxito!",
            text: "Solicitud Actualizada",
            icon: "success",
            button: "Aceptar",
          });
          closeandRefresh();
          document.querySelector(
            ".swal-button--confirm"
          ).style.backgroundColor = colorUI;
        } else {
          closeandRefresh();
          if (form.enviar_email === 0) {
            window.open(dataDatos.data.linkModificacion, "_blank");
          }
        }
      } else {
        swal({
          title: "¡Error!",
          text: dataDatos.error,
          icon: "error",
        });
      }
    } else {
      const claveInstitucion = form.claveInstitucion
        ? form.claveInstitucion
        : form.clave_Institucion;

      const validacion = {
        folio: form.folio,
        estatus: form.estatus,
        clave_profesion: form.clave_profesion,
        clave_institucion: claveInstitucion,
        institucion: form.nombreInstitucion,
        entidad: form.estado,
      };

      const institucionText = form.nombreInstitucion !== "";
      const entidadText = form.estado !== "";
      const claveInstitucionSelect = !!form.claveInstitucion;
      const claveInstitucionText = form.clave_Institucion !== "";

      if (
        claveInstitucionSelect ||
        (institucionText && entidadText && claveInstitucionText)
      ) {
        if (form.estatus === 2) {
          const dataDatos = await services(
            "POST",
            "see/profesiones/validarRegistroTitulo",
            validacion
          );

          if (dataDatos.status === 200) {
            swal({
              title: "¡Éxito!",
              text: "Solicitud actualizada correctamente.",
              icon: "success",
              button: "Aceptar",
            }).then(() => {
              closeandRefresh();
            });
          } else if (dataDatos.status === 422) {
            swal({
              title: "Cuidado",
              text: dataDatos.data,
              icon: "warning",
            });
          } else {
            swal({
              title: "¡Error!",
              text: "Todos los campos son requeridos",
              icon: "warning",
            });
          }
        } else {
          swal({
            title: "Atención",
            text: "Todos los campos son requeridos",
            icon: "warning",
          });
        }
      } else {
        swal({
          title: "¡Error!",
          text: "Todos los campos son requeridos",
          icon: "warning",
        });
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      obtDatos();
      loadObservations();
    }
  }, []);

  useEffect(() => {
    if (aceptadoRef.current) {
      aceptadoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [aceptado]);

  useEffect(() => {
    if (!rechazado && rechazadoRef.current) {
      rechazadoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [rechazado]);

  return (
    <>
      {loading ? <GifLoader /> : null}
      <div>
        <Modal
          open={true}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Detalles de Título Profesional
              </Typography>
              <IconButton
                size="large"
                onClick={() => {
                  close();
                }}
              >
                <CloseRoundedIcon fontSize="inherit"></CloseRoundedIcon>
              </IconButton>
            </Box>

            <Divider />
            <Grid container spacing={1}>
              <Grid className="my-4 d-flex justify-content-center" item md={12}>
                {data.estatus === "Validada" && data.data.pago ? (
                  <a
                    href={data.data.pago}
                    target="_blank"
                    rel="noreferrer"
                    className="cta cta--guinda p-3 text-white"
                    style={{ width: "max-content", borderRadius: "10px" }}
                  >
                    Generar formato de pago <InsertDriveFileIcon />
                  </a>
                ) : data.estatus === "Terminada" && data.data.constancia ? (
                  <a
                    href={data.data.constancia}
                    target="_blank"
                    rel="noreferrer"
                    className="cta cta--guinda p-3 text-white"
                    style={{ width: "max-content", borderRadius: "10px" }}
                  >
                    Descargar documento <FileDownloadIcon />
                  </a>
                ) : null}
              </Grid>
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>CURP: </strong> {datos.data?.solicitante?.curp}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Nombre:</strong> {datos.data?.solicitante?.nombre}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Teléfono:</strong> {datos.data?.solicitante?.telefono}
                </Typography>
              </Grid>

              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Correo: </strong> {datos.data?.solicitante?.email}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Calle: </strong> {datos.data?.solicitante?.calle}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Número: </strong> {datos.data?.solicitante?.no_ext}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Entidad Federativa: </strong>{" "}
                  {datos.data?.solicitante?.entidad_fede}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Municipio: </strong>{" "}
                  {datos.data?.solicitante?.municipio}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Código Postal: </strong>{" "}
                  {datos.data?.solicitante?.codigo_Post}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Colonia: </strong> {datos.data?.solicitante?.colonia}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Localidad: </strong>{" "}
                  {datos.data?.solicitante?.localidad}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Profesión: </strong> {datos.data?.profesion}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Institución: </strong> {datos.data?.institucion}
                </Typography>
              </Grid>
              {datos.data?.numero_cedula && (
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 4 }}>
                    <strong>Número de cédula: </strong>{" "}
                    {datos.data?.numero_cedula}
                  </Typography>
                </Grid>
              )}

              <Grid item md={6}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Fecha titulación: </strong>{" "}
                  {datos.data?.fecha_titulacion}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Fecha expedición de título: </strong>{" "}
                  {datos.data?.fecha_expedicion}
                </Typography>
              </Grid>

              <Grid item md={4}>
                <Typography sx={{ mt: 2, textAlign: "center" }}>
                  <strong>Identificación oficial:</strong>
                </Typography>
                <iframe
                  id="frameFotografia"
                  src={datos.data?.solicitante?.identificacion_archivo}
                  className="preview-iframe"
                ></iframe>
                <a
                  target="_blank"
                  className="botonDescargaArchivoCedula"
                  href={datos.data?.solicitante?.identificacion_archivo}
                >
                  Ver archivo
                </a>
              </Grid>
              <Grid item md={4}>
                <Typography sx={{ mt: 2, textAlign: "center" }}>
                  <strong>Título profesional:</strong>
                </Typography>
                <iframe
                  id="frameFotografia"
                  src={datos.data?.titulo_archivo}
                  className="preview-iframe"
                ></iframe>
                <a
                  target="_blank"
                  className="botonDescargaArchivoCedula"
                  href={datos.data?.titulo_archivo}
                >
                  Ver archivo
                </a>
              </Grid>
              <Grid item md={4}>
                <Typography sx={{ mt: 2, textAlign: "center" }}>
                  <strong>Cédula profesional / Acta de titulación:</strong>
                </Typography>
                <iframe
                  id="frameFotografia"
                  src={datos.data?.cedula_archivo}
                  className="preview-iframe"
                ></iframe>
                <a
                  target="_blank"
                  className="botonDescargaArchivoCedula"
                  href={datos.data?.cedula_archivo}
                >
                  Ver archivo
                </a>
              </Grid>
              <Grid item md={4}>
                <Typography sx={{ mt: 2, textAlign: "center" }}>
                  <strong>CURP:</strong>
                </Typography>
                <iframe
                  id="frameFotografia"
                  src={datos.data?.solicitante?.curp_archivo}
                  className="preview-iframe"
                ></iframe>
                <a
                  target="_blank"
                  className="botonDescargaArchivoCedula"
                  href={datos.data?.solicitante?.curp_archivo}
                >
                  Ver archivo
                </a>
              </Grid>
            </Grid>

            {qrData ? (
              <div className="d-flex justify-content-center my-5">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <Typography sx={{ mt: 2, textAlign: "center" }}>
                    <strong>QR:</strong>
                  </Typography>
                  <iframe
                    id="frameFotografia"
                    src={`data:application/pdf;base64,${qrData}`}
                    className="preview-iframe"
                  ></iframe>

                  <Box display="flex" justifyContent="flex-end" mt={1}>
                    <ColorButton onClick={handlePrintQR}>
                      Descargar QR
                    </ColorButton>
                  </Box>
                </div>
              </div>
            ) : null}

            {/* ENVIAR notas del expediente  */}
            {datos.estatus === "Rechazada" || datos.estatus === "Terminada" ? (
              <Grid className="container my-4" item xs={12} sm={6} md={3}>
                <FormControl style={{ width: "100%" }}>
                  <Typography
                    sx={{ fontSize: 16 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    <strong>
                      {datos.estatus === "Rechazada"
                        ? "Observaciones"
                        : "Notas del expediente"}
                    </strong>
                  </Typography>
                  <textarea
                    disabled={datos.estatus === "Rechazada"}
                    className={`comentariosNotasExpendiente ${
                      datos.estatus === "Rechazada" ? "opacity-50" : ""
                    }`}
                    placeholder="Escribe aqui las notas del expediente"
                    onChange={(e) => {
                      setObservations({
                        ...observations,
                        observaciones: e.target.value,
                      });
                    }}
                    id="obs_fisica"
                    name="obs_fisica"
                    maxLength={500}
                    value={observations.observaciones}
                    style={{
                      width: "100%",
                      height: 140,
                    }}
                  />
                </FormControl>
                <ColorButton
                  className="mt-4"
                  variant="outlined"
                  onClick={() => {
                    if (datos.estatus === "Rechazada" && datos.concepto) {
                      window.open(`/educacion/titulos/${data.folio}`, "_blank");
                    } else {
                      sendObservations(data.folio);
                    }
                  }}
                >
                  {datos.estatus === "Rechazada" ? (
                    <>
                      Modificar solicitud
                      <LaunchIcon style={{ marginLeft: 8 }} />
                    </>
                  ) : (
                    "Enviar notas del expediente"
                  )}
                </ColorButton>
              </Grid>
            ) : null}

            {(rolUser === "Administrador" || rolUser === "adminArea") &&
            datos.estatus === "Pendiente de firma" ? (
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <ColorButton onClick={() => saveDirector()}>
                  Aceptar
                </ColorButton>
              </Box>
            ) : null}
            {datos.estatus === "Abierto" ||
            datos.estatus === "En cambio" ||
            datos.estatus === "En Proceso" ? (
              <Box
                id="botones"
                sx={{ mt: 2 }}
                display="flex"
                justifyContent="flex-end"
                hidden={botones}
              >
                <Button
                  onClick={handleRechazado}
                  variant="contained"
                  color="error"
                >
                  Rechazar
                </Button>
                <Button
                  onClick={handleAceptado}
                  sx={{ ml: 2 }}
                  variant="contained"
                  style={{ backgroundColor: "#6f1d46" }}
                >
                  Aceptar
                </Button>
              </Box>
            ) : (
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <ColorButton onClick={() => closeandRefresh()}>
                  Cerrar
                </ColorButton>
              </Box>
            )}

            <Box
              id="rechazado"
              hidden={rechazado}
              ref={rechazadoRef}
              tabIndex={-1}
            >
              {form.enviar_email === 0 ? null : (
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    id="standard-multiline-static"
                    name="observaciones"
                    onChange={handleChange}
                    value={form.observaciones}
                    label="Observaciones"
                    multiline
                    // rows={2}
                    variant="standard"
                  />
                </Typography>
              )}

              <FormControlLabel
                className="mt-3"
                control={
                  <Checkbox
                    checked={form.enviar_email === 1}
                    onChange={handleCheckboxEmail}
                  />
                }
                label="Edición por usuario"
              />
              <br />
              <span className="text-danger legend-font">
                <strong>NOTA:</strong> Al activar esta opción, se enviará un
                correo al usuario para que realice la edición de la solicitud.
              </span>
              <Divider sx={{ mt: 2 }} />
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <ColorButton onClick={guardar}>
                  {form.enviar_email === 1 ? (
                    "Guardar y Enviar"
                  ) : (
                    <>
                      Editar
                      <LaunchIcon style={{ marginLeft: 8 }} />
                    </>
                  )}
                </ColorButton>
              </Box>
            </Box>
            <Box
              id="aceptado"
              ref={aceptadoRef}
              sx={{ mt: 3 }}
              hidden={aceptado}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="clave_profesion"
                    value={form.clave_profesion}
                    inputProps={{ maxLength: 15 }}
                    onChange={handleChange}
                    label="Clave de profesión"
                    variant="standard"
                  />
                </Grid>

                <Grid item md={7} className="me-3 mt-4">
                  {!mostrarCamposInstitucion ? (
                    <SelectBusqueda
                      opciones={instituciones.instituciones}
                      funcion={handleChangeInstitucion}
                      selectOpcion={form.selectOpcion}
                    />
                  ) : null}
                </Grid>

                <Grid className="mt-5 ms-5" item md={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox onChange={handleCheckboxChange} />}
                      label="Otra institución"
                    />
                  </FormGroup>
                </Grid>
                {mostrarCamposInstitucion && (
                  <>
                    <Grid item md={6}>
                      <Box
                        component="form"
                        sx={{ "& > :not(style)": { width: "35ch" } }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          required
                          id="estado"
                          name="estado"
                          value={form.estado}
                          onChange={handleEstado}
                          label="Estado:"
                          variant="standard"
                        />
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box
                        component="form"
                        sx={{ "& > :not(style)": { width: "35ch" } }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          required
                          id="nombreInstitución"
                          name="nombreInstitución"
                          value={form.nombreInstitucion}
                          onChange={handleNombreInstitucion}
                          label="Nombre de Institución:"
                          variant="standard"
                        />
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box
                        component="form"
                        sx={{ "& > :not(style)": { width: "35ch" } }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          required
                          id="claveInstitucion"
                          name="claveInstitucion"
                          value={form.clave_Institucion}
                          onChange={handleClaveInstitucion}
                          label="Clave de la Institución:"
                          variant="standard"
                        />
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
              <Divider sx={{ mt: 2 }} />
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <ColorButton onClick={guardar}>Guardar y Enviar</ColorButton>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ModalCertificadoCedula;
