import { useState, useRef, useContext } from "react";
import GifLoader from "../components/ui/gifLoader";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Dialog,
  Box,
  Tabs,
  Tab,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { services } from "../services/api";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { NotificacionContext } from "../context/notificacion_context";
import { TextField } from "@material-ui/core";
import { Button } from "react-bootstrap";
import swal from "sweetalert";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import imgInfo from "../assets/img/IMG.png";
import logo4 from "../assets/img/logo4.png";

function CertificadoVehicularView() {
  const notification = useContext(NotificacionContext);
  const [loading, setLoading] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [state, setState] = useState({
    rfc: "",
    comercial: "",
    contrato: "",
    email: "",
  });
  const [response, setResponse] = useState({
    estatus: "",
    NOMBRE: "",
    RFC: "",
    rfc: "",
    comercial: "",
    contrato: "",
    modelo: "",
  });
  const [bodyLinea, setbodyLinea] = useState({
    email: "",
    rfc: "",
    nombre: "",
  });

  const handleBody = ({ target }) => {
    let { value, name } = target;
    setbodyLinea({ ...bodyLinea, [name]: value });
  };

  const handleRFCBody = ({ target }) => {
    let { value, name } = target;
    setbodyLinea({ ...bodyLinea, [name]: value.toUpperCase() });
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validateInterlocutor = (interlocutor) => {
    const re = /^\d+$/;
    return re.test(String(interlocutor));
  };

  const [checked, setchecked] = useState(false);
  const containerRef = useRef(null);
  const handleConsulta = async () => {
    if (state.comercial === "" || state.rfc === "") {
      notification.showNotification(
        "warning",
        "Campos Vacios",
        "Por favor rellene los campos",
        null,
        4
      );
    } else if (!validateEmail(state.email)) {
      notification.showNotification(
        "warning",
        "Email Inválido",
        "Por favor ingrese un correo electrónico válido",
        null,
        4
      );
    } else if (!validateInterlocutor(state.comercial)) {
      notification.showNotification(
        "warning",
        "Número de interlocutor comercial inválido",
        "El campo interlocutor comercial debe ser un número",
        null,
        4
      );
    } else {
      setLoading(true);
      let body = {
        tp_cuenta: value === 0 ? "03" : "04",
        rfc: state.rfc.toUpperCase(),
        interlocutor: state.comercial.toUpperCase(),
        email: state.email,
      };
      let apiVehicular = await services("POST", "nomina/certificado", body);
      // console.log(apiVehicular);
      if (apiVehicular.error) {
        notification.showNotification(
          "warning",
          "Error",
          apiVehicular.error,
          null,
          4
        );
      } else if (apiVehicular.data.create === "false") {
        notification.showNotification(
          "error",
          "Error",
          apiVehicular.data.error,
          null,
          4
        );
      } else {
        if (apiVehicular.data.TB_OBJETO.length > 1) {
          let uniqueObject = {
            TB_OBJETO: apiVehicular.data.TB_OBJETO[0],
          };
          setResponse(uniqueObject);
        } else {
          setResponse(apiVehicular.data);
        }
        setchecked(true);
      }
      setLoading(false);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const generateLinea = async () => {
    let body = {
      ...bodyLinea,
      placa: state.placa.toUpperCase(),
      serie: state.serie.toUpperCase(),
    };
    let apiGenerar = await services("POST", "vehicular/generaLineacap", body);
    // console.log(apiGenerar);

    if (apiGenerar.data) {
      setopenDialog(false);
      swal("Se generó línea de captura con éxito", {
        buttons: {
          linea: "Ver Línea de Captura.",
        },
      }).then((result) => {
        window.location = apiGenerar.data?.intento;
      });
    } else {
      notification.showNotification(
        "error",
        "Certificado",
        "Hubo un error, intente más tarde",
        null,
        4
      );
    }
  };

  // const handleIntento = async () => {
  //   // if(response.estatus === 1){
  //   //   window.open( response.intento)
  //   // }else if(response.estatus === 2){
  //   //   downloadCertificado()
  //   // }
  //   setLoading(true);
  //   let body = {
  //     tp_cuenta: value === 0 ? "03" : "04",
  //     rfc: state.rfc,
  //     interlocutor: state.comercial,
  //     email: state.email,
  //   };
  //   let apiVehicular = await services("POST", "nomina/generaConstancia", body);
  //   console.log(apiVehicular);
  //   if (apiVehicular.data.constancia) {
  //     notification.showNotification(
  //       "success",
  //       "Listo",
  //       apiVehicular.error,
  //       null,
  //       4
  //     );
  //     let base64Doc = apiVehicular.data.constancia;
  //     downloadPDF(base64Doc, "Solicitud_de_impuesto_estatal", null, "pdf");
  //   }
  //   setLoading(false);
  // };

  const handleIntento = async () => {
    setLoading(true);
    let body = {
      tp_cuenta: value === 0 ? "03" : "04",
      rfc: state.rfc,
      interlocutor: state.comercial,
      email: state.email,
    };

    let apiVehicular = await services("POST", "nomina/generaConstancia", body);
    // console.log(apiVehicular);

    if (apiVehicular.data.create === "false") {
      notification.showNotification(
        "error",
        "Error",
        apiVehicular.data.error,
        null,
        4
      );
    } else if (apiVehicular.data.constancia) {
      notification.showNotification(
        "success",
        "Listo",
        "La constancia se ha generado correctamente.",
        null,
        4
      );
      let base64Doc = apiVehicular.data.constancia;
      downloadPDF(base64Doc, "Solicitud_de_impuesto_estatal", null, "pdf");
    } else {
      notification.showNotification(
        "warning",
        "Advertencia",
        "No se pudo generar la constancia, por favor intente nuevamente.",
        null,
        4
      );
    }

    setLoading(false);
  };

  const downloadPDF = (documentLink, name, callback, extension) => {
    try {
      const linkSource = `data:application/${extension};base64,${documentLink}`;
      const downloadLink = document.createElement("a");
      const fileName = `${name}.${extension}`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      if (callback !== null) {
        callback();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openImgInfo, setopenImgInfo] = useState(false);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleImage = () => {
    setopenImgInfo(true);
    // let image = document.createElement('a')
    // image.setAttribute('href', imgInfo )
    // image.setAttribute('target', '_blank')
    // image.click()
  };
  return (
    <>
      {loading && <GifLoader />}
      <div className="vh-100 w-75 mx-auto">
        <div
          style={{ overflow: "hidden" }}
          ref={containerRef}
          className="h-75 d-flex justify-content-center card m-3 text-center"
        >
          {!checked && (
            <div className=" text-big mb-2 text-bold">
              {/* <Button sx={{backgroundColorp}}> Solicitud de duplicados</Button> */}
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  centered
                  aria-label="basic tabs example"
                >
                  <Tab label="Nómina" {...a11yProps(0)} />
                  <Tab label="Hospedaje" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </div>
          )}
          {!checked && (
            <div className="my-auto">
              {value === 0 && (
                <Slide
                  direction="right"
                  in={value === 0}
                  container={containerRef.current}
                >
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        className="imgLogo4"
                        alt="Imagen Decorativa"
                        src={logo4}
                        style={{
                          width: "180px",
                          Height: "180px",
                          marginLeft: "80px",
                        }}
                      />
                      <span className="text-big text-vinoGob text-bold sizeletrasgobTitle navidad">
                        Solicitud del Impuesto Estatal Sobre Nómina y Hospedaje
                      </span>
                    </div>

                    {/* NOMINA */}
                    <FormControl
                      sx={{ marginTop: 3, width: 400, maxWidth: "100%" }}
                    >
                      <InputLabel htmlFor="my-input">RFC</InputLabel>
                      <Input
                        onChange={(event) => {
                          const uppercasedValue =
                            event.target.value.toUpperCase();
                          setState({ ...state, rfc: uppercasedValue });
                        }}
                        onKeyPress={(event) => {
                          // onlyLetter(event, 29);
                        }}
                        inputProps={{
                          style: { textTransform: "uppercase" },
                          maxLength: 18,
                        }}
                        id="nombre"
                        name="nombre"
                        aria-describedby="my-helper-text"
                      />
                      <FormHelperText id="my-helper-text">
                        Ingresa tu RFC.
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      sx={{ marginTop: 3, width: 400, maxWidth: "100%" }}
                    >
                      <InputLabel htmlFor="my-input">
                        Interlocutor Comercial
                      </InputLabel>
                      <Input
                        onChange={(event) => {
                          setState({ ...state, comercial: event.target.value });
                        }}
                        onKeyPress={(event) => {
                          // onlyLetter(event, 29);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <Tooltip title="¿De dónde obtengo este dato? Haz clic en el icono">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleImage}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                <HelpOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        }
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        id="nombre"
                        name="nombre"
                        aria-describedby="my-helper-text"
                      />
                      <FormHelperText id="my-helper-text">
                        Ingresa tu interlocutor comercial.
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      sx={{ marginTop: 3, width: 400, maxWidth: "100%" }}
                    >
                      <InputLabel htmlFor="my-input">
                        Correo Electrónico
                      </InputLabel>
                      <Input
                        onChange={(event) => {
                          setState({ ...state, email: event.target.value });
                        }}
                        onKeyPress={(event) => {
                          // onlyLetter(event, 29);
                        }}
                        id="nombre"
                        name="nombre"
                        aria-describedby="my-helper-text"
                      />
                      <FormHelperText id="my-helper-text">
                        Ingresa tu correo electrónico.
                      </FormHelperText>
                    </FormControl>

                    <div
                      className="cta cta--guinda mt-2"
                      onClick={() => {
                        handleConsulta();
                      }}
                    >
                      Consultar
                    </div>
                  </div>
                </Slide>
              )}
              {value === 1 && (
                <Slide
                  direction="left"
                  in={value === 1}
                  container={containerRef.current}
                >
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        className="imgLogo4"
                        alt="Imagen Decorativa"
                        src={logo4}
                        style={{
                          width: "180px",
                          Height: "180px",
                          marginRight: "20px",
                        }}
                      />
                      <span className="text-big text-vinoGob text-bold sizeletrasgobTitle navidad">
                        Solicitud del Impuesto Estatal Sobre Hospedaje
                      </span>
                    </div>

                    {/* HOSPEDAJE */}
                    <FormControl
                      sx={{ marginTop: 3, width: 400, maxWidth: "100%" }}
                    >
                      <InputLabel htmlFor="my-input">RFC</InputLabel>
                      <Input
                        error={state.serie}
                        onChange={(event) => {
                          const uppercasedValue =
                            event.target.value.toUpperCase();
                          setState({ ...state, rfc: uppercasedValue });
                        }}
                        onKeyPress={(event) => {
                          // onlyLetter(event, 29);
                        }}
                        inputProps={{
                          style: { textTransform: "uppercase" },
                          maxLength: 18,
                        }}
                        id="nombre"
                        name="nombre"
                        aria-describedby="my-helper-text"
                      />
                      <FormHelperText id="my-helper-text">
                        Ingresa tu RFC.
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      sx={{ marginTop: 3, width: 400, maxWidth: "100%" }}
                    >
                      <InputLabel htmlFor="my-input">
                        Interlocutor Comercial
                      </InputLabel>
                      <Input
                        error={state.placa}
                        onChange={(event) => {
                          setState({ ...state, comercial: event.target.value });
                        }}
                        onKeyPress={(event) => {
                          // onlyLetter(event, 29);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <Tooltip title="¿De dónde obtengo este dato? Haz clic en el icono">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleImage}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                <HelpOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        }
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        id="nombre"
                        name="nombre"
                        aria-describedby="my-helper-text"
                      />
                      <FormHelperText id="my-helper-text">
                        Ingresa tu Interlocutor Comercial.
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      sx={{ marginTop: 3, width: 400, maxWidth: "100%" }}
                    >
                      <InputLabel htmlFor="my-input">
                        Correo Electronico
                      </InputLabel>
                      <Input
                        error={state.email}
                        onChange={(event) => {
                          setState({ ...state, email: event.target.value });
                        }}
                        onKeyPress={(event) => {
                          // onlyLetter(event, 29);
                        }}
                        id="nombre"
                        name="nombre"
                        aria-describedby="my-helper-text"
                      />
                      <FormHelperText id="my-helper-text">
                        Ingresa tu correo electronico.
                      </FormHelperText>
                    </FormControl>

                    <div
                      className="cta cta--guinda mt-2"
                      onClick={() => {
                        handleConsulta();
                      }}
                    >
                      Consultar
                    </div>
                  </div>
                </Slide>
              )}
            </div>
          )}

          {checked && (
            <Slide
              direction="left"
              in={checked}
              container={containerRef.current}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                {response.TB_OBJETO && (
                  <div className="subContainer p-3">
                    <h3>Datos del Contribuyente</h3>
                    <div className="d-flex flex-wrap mt-2 justify-content-center">
                      <span className="m-3">
                        RFC: <strong>{response?.TB_OBJETO?.RFC}</strong>
                      </span>
                      <span
                        className={`m-3 ${
                          response?.TB_OBJETO?.NOM_COM == null ? "d-none" : ""
                        }`}
                      >
                        RAZON SOCIAL:{" "}
                        <strong>
                          {" "}
                          {response?.TB_OBJETO.NOMBRE ??
                            response?.TB_OBJETO?.NOM_COM}
                        </strong>
                      </span>
                      <span
                        className={`m-3 ${
                          response?.TB_OBJETO?.ESTATUS_ADEUDO == null
                            ? "d-none"
                            : ""
                        }`}
                      >
                        ESTATUS ADEUDO:{" "}
                        <strong> {response?.TB_OBJETO?.ESTATUS_ADEUDO}</strong>
                      </span>
                      <span className="m-3">
                        ESTATUS CERTIFICADO:{" "}
                        <strong> {response?.TB_OBJETO?.EST_CERT}</strong>
                      </span>
                    </div>

                    <div className="d-flex mt-2 justify-content-center">
                      <div
                        className="cta cta--guinda mx-2"
                        onClick={() => {
                          setchecked(false);
                        }}
                      >
                        Volver a Consultar{" "}
                      </div>
                      <div
                        className="cta cta--guinda "
                        onClick={() => {
                          handleIntento();
                        }}
                      >
                        Generar Constancia
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Slide>
          )}
        </div>
      </div>
      <Dialog
        open={openImgInfo}
        onClose={() => setopenImgInfo(false)}
        aria-labelledby="alert-dialog-title"
        maxWidth="lg"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Como identificar el interlocutor comercial
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img
              style={{ width: "100%", height: "100%" }}
              src={imgInfo}
              alt="info"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setopenImgInfo(false)}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialog}
        onClose={() => setopenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Generar Línea de Captura
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Para generar un línea de Captura, necesitamos que nos proporciones
            un correo electrónico
          </DialogContentText>
          <TextField
            fullWidth
            label="Email"
            size="small"
            variant="standard"
            value={bodyLinea.email}
            name="email"
            onChange={handleBody}
          />

          <small>*Verifica que tu informacion este bien</small>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setopenDialog(false)}>
            Cancelar
          </Button>
          <Button onClick={() => generateLinea()}>Generar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CertificadoVehicularView;
