import { useContext, useEffect, useState, useRef } from "react";
import { NotificacionContext } from "../context/notificacion_context";
import { services } from "../services/api";

let iframe;
let channel = new MessageChannel();
let port1 = channel.port1;

export const usePINPAD = ({ state, setState, setLoaderPinpad }) => {
  const { showNotification } = useContext(NotificacionContext);
  const userPinpad = localStorage.getItem("user");
  const datosU = localStorage.getItem("datosPINPAD");
  const datosUsuarioPINPAD = localStorage.getItem("datosUsuarioPINPAD");
  const [respCompra, setrespCompra] = useState(null);
  const user = JSON.parse(localStorage.getItem("usuario"));
  const isFirstRender = useRef(false);
  const flagCompra = useRef(true);
  let urlPINPAD = user?.urlPINPAD;
  let Ambiente = user?.Ambiente;
  let operationType = user?.operationType;

  const initValues = {
    Ambiente,
    dataUserConfig: "",
    dataPpConfig: "",
    name: "initValues",
  };

  const objDataLogin = {
    Ambiente,
    Usuario: "",
    Pass: "",
    name: "login",
  };

  const objDataKeys = {
    Ambiente,
    Usuario: "",
    Pass: "",
    Country: "",
    IdBranch: "",
    IdCompany: "",
    name: "getKeysRSA",
  };

  const objDataTRX = {
    Ambiente,
    Currency: "MXN",
    CurrencyCode: "0484",
    Amount: "",

    //Seccion Pinpad
    TimeOutPinPad: "60",
    MarcaTerminal: "",
    ModeloTerminal: "",
    name: "readCard",
  };

  const objDataMerchant = {
    Ambiente,
    BIN: "",
    User: "",
    Currency: objDataTRX.Currency,
    Tx_OperationType: operationType,
    name: "getMerchant",
  };

  const objDataVenta = {
    Ambiente,
    Country: "",
    IdBranch: "",
    IdCompany: "",
    pwd: "",
    User: "",
    UserTRX: "userPinpadWeb",
    EMV: "",
    ModeloTerminal: "",
    SerieTerminal: "",
    Printer: "",
    VersionTerminal: "",
    TpOperation: operationType,
    Reference: "",
    //Amount: '',
    Currency: objDataTRX.Currency,
    Merchant: "",
    name: "sndVentaDirectaEMV",
  };

  const objPrint = {
    Ambiente,
    VoucherComercio: "",
    VoucherCliente: "",
    ModeloTerminal: "",
    name: "printVoucher",
  };

  const objDataReimpresion = {
    Ambiente,
    User: "",
    Pwd: "",
    IdBranch: "",
    IdCompany: "",
    Country: "",
    Tx_OperationNumber: "",
    name: "sndReimpresion",
  };

  const objDataConsulta = {
    Ambiente,
    User: "",
    Pwd: "",
    IdBranch: "",
    IdCompany: "",
    Country: "",
    Tx_Date: "",
    Reference: "",
    name: "sndConsulta",
  };

  //EFFECT GLOBAL
  useEffect(() => {
    if (localStorage.getItem("serial") === "0") return;
    iframe = document.getElementById("iframeTo");
    if (iframe) {
      setLoaderPinpad(true);
      iframe.addEventListener("load", onLoad);
    }
    return () => {
      if (iframe) {
        iframe.removeEventListener("load", onLoad);
      }
    };
  }, []);

  //EFFECT Consulta Automatica
  useEffect(() => {
    if (isFirstRender.current) {
      flagCompra.current = false;
    }
    isFirstRender.current = true;
  }, [respCompra]);

  const onLoad = () => {
    try {
      iframe.contentWindow.postMessage("message", "*", [channel.port2]);
    } catch (error) {
      channel = new MessageChannel();
      port1 = channel.port1;
      iframe.contentWindow.postMessage("message", "*", [channel.port2]);
    }

    if (datosU === null || datosUsuarioPINPAD === null || userPinpad === null) {
      loginPinpad();
    } else {
      initValuesPinpad();
    }
  };

  const cargarPuertoPinpad = async () => {
    const filters = [{ usbVendorId: 4554 }];
    let puerto = "0";
    await navigator.serial
      .requestPort({ filters })
      .then((e) => {
        puerto = "1";
      })
      .catch((e) => {
        console.warn("No se selecciono puerto");
      });
    localStorage.setItem("serial", puerto);
  };

  const loginPinpad = () => {
    const { usuario_pinpad, password_pinpad } = user;

    objDataLogin.Usuario = usuario_pinpad;
    objDataLogin.Pass = password_pinpad;

    let portL = port1;
    portL.postMessage(objDataLogin);
    portL.onmessage = (event) => {
      let response = event.data;
      let obj = JSON.parse(response);
      let aux = obj["RESPUESTA"];
      if (aux == null || aux == "error") {
        showNotification("error", "Error PINPAD", obj["ERROR"], null, 4);
        setLoaderPinpad(false);
      } else {
        localStorage.setItem("datosUsuarioPINPAD", response);
        localStorage.setItem("user", usuario_pinpad + "|" + password_pinpad);
        GetKeysRSA();
      }
    };
  };

  const GetKeysRSA = () => {
    const { usuario_pinpad, password_pinpad } = user;
    const { bs_country, bs_branch, bs_company } = JSON.parse(
      localStorage.getItem("datosUsuarioPINPAD")
    );
    objDataKeys.Usuario = usuario_pinpad;
    objDataKeys.Pass = password_pinpad;
    objDataKeys.Country = bs_country;
    objDataKeys.IdBranch = bs_branch;
    objDataKeys.IdCompany = bs_company;

    let portL = port1;
    portL.postMessage(objDataKeys);
    portL.onmessage = (event) => {
      let response = event.data;
      if (response) {
        localStorage.setItem("datosPINPAD", response);
        let obj = JSON.parse(response);
        let aux = obj["RESPUESTA"];
        if (aux == null || aux == "error") {
          showNotification("error", "Error", obj["ERROR"], null, 4);
        }
      }
      setLoaderPinpad(false);
    };
  };

  const initValuesPinpad = () => {
    const { dataUserConfig } = JSON.parse(datosUsuarioPINPAD);
    const { dataPpConfig } = JSON.parse(datosU);

    initValues.dataUserConfig = dataUserConfig;
    initValues.dataPpConfig = dataPpConfig;

    let portL = port1;
    portL.postMessage(initValues);
    portL.onmessage = (event) => {
      let obj = JSON.parse(event.data);
      let aux = obj["RESPUESTA"];
      if (aux == null || aux == "error") {
        showNotification("error", "Error", obj["ERROR"], null, 4);
      }
      setLoaderPinpad(false);
    };
  };

  const readCard = (amount) => {
    const { marca, modelo } = JSON.parse(datosU);
    objDataTRX.Amount = amount;
    objDataTRX.MarcaTerminal = marca;
    objDataTRX.ModeloTerminal = modelo;

    let portL = port1;
    portL.postMessage(objDataTRX);
    portL.onmessage = (event) => {
      let objTRX = JSON.parse(event.data);
      let aux = objTRX["RESPUESTA"];
      if (aux == null || aux == "error") {
        showNotification("error", "Error", objTRX["ERROR"], null, 4);
        setLoaderPinpad(false);
        return;
      } else {
        showNotification(
          "success",
          "EXITO",
          "Tarjeta leida correctamente",
          null,
          4
        );
        getMerchant(objTRX);
      }
    };
  };

  const getMerchant = (trx) => {
    const { bs_user } = JSON.parse(datosUsuarioPINPAD);
    let binT;
    if (trx.maskPan.length > 6) binT = trx.maskPan.substring(0, 6);

    objDataMerchant.BIN = binT;
    objDataMerchant.User = bs_user;

    let portL = port1;
    portL.postMessage(objDataMerchant);
    portL.onmessage = (event) => {
      let objTRX = JSON.parse(event.data);
      let aux = objTRX["respuesta"];
      if (aux == null || aux == "0") {
        let respMer = objTRX["nb_respuesta"];
        if (respMer == "" || respMer == null || respMer == undefined)
          respMer = objTRX["ERROR"];
        showNotification("error", "Error", respMer, null, 4);
      }
      setLoaderPinpad(false);
      setState({
        ...state,
        noTarjeta: trx.maskPan,
        nombreTarjetaHabiente: trx.name,
        fechaVencimiento: `${trx.month}/${trx.year}`,
        showmodalAfiliaciones: true,
        contado: objTRX.contado,
        msi: objTRX.msi,
      });
    };
  };

  const cobrar = async () => {
    flagCompra.current = true;
    const { bs_country, bs_branch, bs_company } =
      JSON.parse(datosUsuarioPINPAD);
    const { EMV, modelo, serie, impresora, versionApp } = JSON.parse(datosU);
    const { usuario_pinpad, password_pinpad } = user;

    objDataVenta.Country = bs_country;
    objDataVenta.IdBranch = bs_branch;
    objDataVenta.IdCompany = bs_company;
    objDataVenta.pwd = password_pinpad;
    objDataVenta.User = usuario_pinpad;
    objDataVenta.Merchant = state.merchant;
    objDataVenta.Reference = state.referencia;
    objDataVenta.EMV = EMV;
    objDataVenta.ModeloTerminal = modelo;
    objDataVenta.SerieTerminal = serie;
    objDataVenta.Printer = impresora;
    objDataVenta.VersionTerminal = versionApp;

    let respTRX;
    let portL = port1;
    portL.postMessage(objDataVenta);
    portL.onmessage = (event) => {
      if (!flagCompra.current) return;
      let objResponse = JSON.parse(event.data);

      if (objResponse.response != "approved") {
        if (objResponse?.response === "Aplica DCC") {
          showNotification("error", "ERROR!", objResponse, null, 4);
          setLoaderPinpad(false);
          setrespCompra(objResponse);
          return;
        }

        if (objResponse.response === "denied") {
          respTRX = objResponse["friendly_response"];
        } else {
          if (objResponse.response === "error") {
            respTRX =
              "Response: " +
              objResponse["response"] +
              "  /  No. Operación: " +
              objResponse["foliocpagos"] +
              "  /  CodeError: " +
              objResponse["cd_error"] +
              "  /  DesError: " +
              objResponse["nb_error"];
          } else {
            respTRX =
              "Response: " +
              objResponse["RESPUESTA"] +
              "  /  Error: " +
              objResponse["ERROR"] +
              "  /  CodeError: " +
              objResponse["codError"] +
              "  /  DesError: " +
              objResponse["ERROR"];
          }
        }
        showNotification("error", "ERROR!", respTRX, null);
        setLoaderPinpad(false);
        setrespCompra(objResponse);
        return;
      } else {
        respTRX =
          "Response: " +
          objResponse["response"] +
          "  /  No. Operación: " +
          objResponse["foliocpagos"] +
          "  /  No. Auth: " +
          objResponse["auth"] +
          "  /  CdResponse: " +
          objResponse["cd_response"] +
          "  /  Arqc: " +
          objResponse["arqc"] +
          "  /  Aid: " +
          objResponse["appid"] +
          "  /  AidLabel: " +
          objResponse["appidlabel"];

        showNotification(
          "success",
          "Compra realizada correctamente",
          respTRX,
          null
        );
        const { voucher_comercio, voucher_cliente, foliocpagos } = objResponse;
        setState({
          ...state,
          cobrar: true,
          rePrint: false,
          folio: foliocpagos,
        });
        acreditarPago(objResponse);
        printVoucher(voucher_comercio, voucher_cliente);
        setLoaderPinpad(false);
        setrespCompra(objResponse);
      }
    };

    //Consulta automatica
    setTimeout(() => {
      if (flagCompra.current) {
        flagCompra.current = false;
        consultaAutomatica();
      }
    }, 15000);
  };

  const formatDateNow = () => {
    const fecha = new Date();
    let dia = fecha.getDate();
    let mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();

    if (dia < 10) dia = "0" + dia;
    if (mes < 10) mes = "0" + mes;

    return `${dia}/${mes}/${año}`;
  };

  const consultaAutomatica = () => {
    const { usuario_pinpad, password_pinpad } = user;
    const { bs_country, bs_branch, bs_company } =
      JSON.parse(datosUsuarioPINPAD);

    objDataConsulta.User = usuario_pinpad;
    objDataConsulta.Pwd = password_pinpad;
    objDataConsulta.IdBranch = bs_branch;
    objDataConsulta.IdCompany = bs_company;
    objDataConsulta.Country = bs_country;
    objDataConsulta.Tx_Date = formatDateNow();
    objDataConsulta.Reference = state.referencia;

    let respTRX;
    let portL = port1;
    portL.postMessage(objDataConsulta);
    portL.onmessage = (event) => {
      let objResponse = JSON.parse(event.data)?.transaccion;
      if (!objResponse) {
        showNotification(
          "error",
          "ERROR",
          "No se han encontrado pagos registrados con la referencia: " +
          objDataConsulta.Reference,
          null
        );
        setLoaderPinpad(false);
        return;
      }
      if (objResponse.nb_response != "approved") {
        let msgError;
        if (objResponse?.nb_response === "Aplica DCC") {
          showNotification("error", "ERROR!", objResponse, null, 4);
          setLoaderPinpad(false);
          return;
        }
        if (objResponse.nb_response === "denied") {
          msgError = objResponse["friendly_response"];
        }
        showNotification("error", "ERROR!", msgError, null);
        setLoaderPinpad(false);
        return;
      } else {
        respTRX =
          "Response: " +
          objResponse["nb_response"] +
          "  /  No. Operación: " +
          objResponse["nu_operaion"] +
          "  /  No. Auth: " +
          objResponse["nu_auth"] +
          "  /  CdResponse: " +
          objResponse["cd_resp"];

        showNotification(
          "success",
          "Compra realizada correctamente",
          respTRX,
          null
        );
        setLoaderPinpad(false);
        setState({
          ...state,
          cobrar: true,
          rePrint: false,
          folio: objResponse.nu_operaion,
        });

        let objResponseAux = {
          reference: objResponse.nb_referencia,
          response: objResponse.nb_response,
          foliocpagos: objResponse.nu_operaion,
          auth: objResponse.nu_auth,
          time: objResponse.fh_registro,
          date: objResponse.fh_bank,
          cc_type: objResponse.cd_instrumento,
          tp_operation: objResponse.tp_operacion,
          cc_name: objResponse.cc_nombre,
          cc_number: objResponse.cc_num,
          amount: objResponse.nu_importe,
          friendly_response: objResponse.nb_resp,
        };

        acreditarPago(objResponseAux);
        rePrintVoucher(objResponseAux.foliocpagos);
      }
    };
  };

  const printVoucher = (comercio, cliente) => {
    const { impresora, modelo } = JSON.parse(datosU);
    if (impresora != "0") {
      objPrint.VoucherComercio = comercio;
      objPrint.VoucherCliente = cliente;
      objPrint.ModeloTerminal = modelo;

      let portL = port1;
      portL.postMessage(objPrint);
      portL.onmessage = (event) => {
        let objTRX = JSON.parse(event.data);
        let aux = objTRX["RESPUESTA"];
        if (aux == "error") {
          const errorInfo = objTRX?.ERROR;
          showNotification("error", "ERROR", errorInfo, null, 4);
        }
      };
    }
  };

  const rePrintVoucher = (folio) => {
    const { usuario_pinpad, password_pinpad } = user;
    const { bs_country, bs_branch, bs_company } = JSON.parse(
      localStorage.getItem("datosUsuarioPINPAD")
    );

    objDataReimpresion.User = usuario_pinpad;
    objDataReimpresion.Pwd = password_pinpad;
    objDataReimpresion.IdBranch = bs_branch;
    objDataReimpresion.IdCompany = bs_company;
    objDataReimpresion.Country = bs_country;
    objDataReimpresion.Tx_OperationNumber = folio;

    let portL = port1;
    portL.postMessage(objDataReimpresion);
    portL.onmessage = (event) => {
      let objTRX = JSON.parse(event.data);
      let aux = objTRX["RESPUESTA"];
      if (aux == "error") {
        const errorInfo = objTRX?.ERROR;
        showNotification("error", "ERROR", errorInfo, null, 4);
      } else {
        const { voucher_comercio, voucher_cliente } = objTRX;
        printVoucher(voucher_comercio, voucher_cliente);
      }
    };
  };

  const acreditarPago = async (body) => {
    const resAcreditar = await services("POST", "usuarios/validaPinPad", body);
    if (resAcreditar.status !== 200) {
      showNotification(
        "error",
        "Validar Pinpad",
        "Error al conectar al servidor!"
      );
      return;
    }

    if (resAcreditar.data.estatus === false) {

      if(resAcreditar.data.Mensaje.Consulta?.descripcion){
        showNotification(
          "error",
          "Error al acreditar",
          resAcreditar.data.Mensaje.Consulta.descripcion
        );
        return;
      }

      if(resAcreditar.data.Mensaje?.Confirmacion){
        showNotification(
          "error",
          "Error estatus: "+ resAcreditar.data.Mensaje.Confirmacion.estatus,
          resAcreditar.data.Mensaje.Confirmacion.descripcion
        );
        return;
      }

      if(resAcreditar.data?.Mensaje){
        showNotification(
          "error",
          "Error al acreditar",
          resAcreditar.data.Mensaje
        );
        return;
      }

    } else {
      showNotification(
        "success",
        "EXITO!",
        "Acreditacion realizada correctamente",
        null,
        4
      );
    }
  };

  return {
    urlPINPAD,
    cargarPuertoPinpad,
    loginPinpad,
    readCard,
    cobrar,
    rePrintVoucher,
  };
};
