import React, { useState, useEffect, useContext } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Card, TextField } from "@mui/material";
import GifLoader from "../../components/ui/gifLoader";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import swal from "sweetalert";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: "#6f1d46",
  // backgroundColor: "#8d949e",
  "&:hover": {
    backgroundColor: "#6f1d46",
    // backgroundColor: "#8d949e",
  },
}));

export const CancelacionPago = () => {
  const [isLoading, setLoading] = useState(false);
  const [numReferencia, setNumReferencia] = useState("");
  const [numAutorizacion, setNumAutorizacion] = useState("");
  const [validate, setValidate] = useState(false);

  const handleRefChange = (event) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      setNumReferencia(event.target.value);
    }
  };

  const handleAuthChange = (event) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      setNumAutorizacion(event.target.value);
    }
  };

  const consultarReferencia = () => {
    setLoading(true);

    //Logica de: Consumir API de verificación de la referencia...

    if (numReferencia !== "") {
      setTimeout(() => {
        setValidate(true);
        setLoading(false);
      }, 3000);
    } else {
      swal({
        title: "Error",
        text: "Ingrese un número de referencia",
        icon: "error",
        buttons: false,
        timer: 2000,
      });
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading && <GifLoader />}
      <Grid
        container
        spacing={2}
        columns={16}
        justifyContent="center"
        alignContent="center"
        className="my-3"
      >
        <Card
          style={{
            paddingTop: 30,
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 30,
          }}
          variant="outlined"
        >
          <Grid
            container
            sx={{ paddingBottom: "1rem", paddingTop: "2rem" }}
            spacing={2}
            columns={16}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={16} className="d-flex flex-row align-items-center">
              <InputLabel className="mx-2">Linea de Captura:</InputLabel>
              <TextField
                type="text"
                required
                value={numReferencia}
                onChange={handleRefChange}
                InputProps={{
                  sx: {
                    borderRadius: "6px",
                    width: "100%",
                    height: "40px",
                  },
                }}
              />

              <InputLabel className="mx-2">Número de autorización:</InputLabel>
              <TextField
                type="text"
                required
                value={numAutorizacion}
                onChange={handleAuthChange}
                InputProps={{
                  sx: {
                    borderRadius: "6px",
                    width: "100%",
                    height: "40px",
                  },
                }}
              />

              <Grid item xs="auto" className="mx-4">
                <ColorButton
                  variant="outlined"
                  onClick={() => {
                    consultarReferencia();
                  }}
                >
                  Consultar
                </ColorButton>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};
