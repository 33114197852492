import { useContext, useState, useEffect } from "react";
import OverLayIU from "./ui/overlay_ui";
import Logo from "../assets/img/LogoGobMich-Artboard1.png";
import { IoClose } from "react-icons/io5";
import IconUI from "./ui/icon_ui";
import { useHistory } from "react-router-dom";
import {
  FaHome,
  FaBook,
  FaUsers,
  FaRegNewspaper,
  FaMoneyCheckAlt,
} from "react-icons/fa";

import {
  MdOutlinePublishedWithChanges,
  MdPayments,
  MdKeyboardArrowDown,
} from "react-icons/md";
import { BiLogOutCircle } from "react-icons/bi";
import { MdOutlineDynamicForm } from "react-icons/md";
import capturaContext from "../context/captura_context/capturaContext";
import { FaNetworkWired } from "react-icons/fa";

const MenuComponent = ({ onClose }) => {
  const { setCurrentUser } = useContext(capturaContext);

  let usuario = JSON.parse(localStorage.getItem("usuario"));
  // const permisosLineaCaptura = [
  //   "Administrador",
  //   "adminDependencia",
  //   "Ventanilla",
  //   "adminArea",
  // ];
  const permisosSolicitudes = [
    "adminDependencia",
    "Administrador",
    "revisor",
    "adminRevisor",
    "adminArea",
  ];
  const permisosAdminUser = ["Administrador", "adminDependencia", "adminArea"];
  const permisosConfiguradorTramite = ["Administrador", "adminDependencia"];
  const permisosConfGrat = ["adminDependencia", "Administrador", "revisor"];
  const permisosRevisor = ["revisor"];
  const permisosCanje = [
    "Administrador",
    "adminDependencia",
    "Ventanilla",
    "adminArea",
  ];
  const permisosInicio = [
    "Administrador",
    "Ventanilla",
    "adminArea",
    "adminDependencia",
  ];
  const permisosAreas = ["Administrador", "adminDependencia"];

  const permisosPublicador = ["Administrador"];

  const permisosVentanilla = ["Ventanilla"];

  const [state, setState] = useState({
    rol: "",
    toggleCss: false,
  });
  const history = useHistory();
  const logOut = () => {
    setCurrentUser(null);
    localStorage.clear();
    history.replace("/login");
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const rol = localStorage.getItem("rol");
      setState({ ...state, rol });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <OverLayIU onClick={onClose}></OverLayIU>
      <div className="Menu_lateral">
        <div className="d-flex" style={{ height: "10px" }}>
          <div className="col"></div>
          <div className="cursor-pointer" onClick={onClose}>
            <IconUI>
              <IoClose />
            </IconUI>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <img className="logo-header" alt="logo-header" src={Logo} />
        </div>
        <div className="d-flex flex-column h-100">
          {permisosInicio.includes(state.rol) && (
            <div
              className="menu_item"
              onClick={() => {
                history.push("/home");
                onClose();
              }}
            >
              <div className="p-2">
                <IconUI>
                  <FaHome />
                </IconUI>
              </div>
              <div>Inicio</div>
            </div>
          )}
          {permisosCanje.includes(state.rol) &&
            usuario.tieneCategorias === 1 && (
              <div
                className="menu_item"
                onClick={() => {
                  history.push("/ventanilla");
                  onClose();
                }}
              >
                <div className="p-2">
                  <IconUI>
                    <MdOutlinePublishedWithChanges />
                  </IconUI>
                </div>
                <div className="">Canje Línea de Captura</div>
              </div>
            )}

          {state.rol === "Administrador" && (
            <div
              className="menu_item"
              onClick={() => {
                history.push("/dependencias");
                onClose();
              }}
            >
              <div className="p-2">
                <IconUI>
                  <MdPayments />
                </IconUI>
              </div>
              <div className="">Dependencias</div>
            </div>
          )}

          {permisosAreas.includes(state.rol) && (
            <div
              className="menu_item"
              onClick={() => {
                history.push("/areas");
                onClose();
              }}
            >
              <div className="p-2">
                <IconUI>
                  <FaNetworkWired />
                </IconUI>
              </div>
              <div className="">Áreas</div>
            </div>
          )}

          {permisosCanje.includes(state.rol) &&
            usuario.tieneCategorias === 1 && (
              <div
                className="menu_item"
                onClick={() => {
                  history.push("/bitacora");
                  onClose();
                }}
              >
                <div className="p-2">
                  <IconUI>
                    <FaBook />
                  </IconUI>
                </div>
                <div className="">Bitácora</div>
              </div>
            )}

          {permisosSolicitudes.includes(state.rol) &&
            usuario.tieneCategorias === 1 && (
              <div
                className="menu_item"
                onClick={() => {
                  history.push("/educacion/solicitudCertificado");
                  onClose();
                }}
              >
                <div className="p-2">
                  <IconUI>
                    <FaRegNewspaper />
                  </IconUI>
                </div>
                <div className="">Solicitudes</div>
              </div>
            )}
          {permisosAdminUser.includes(state.rol) &&
            usuario.tieneCategorias === 1 && (
              <div
                className="menu_item"
                onClick={() => {
                  history.push("/reportes");
                  onClose();
                }}
              >
                <div className="p-2">
                  <IconUI>
                    <FaRegNewspaper />
                  </IconUI>
                </div>
                <div className="">Reportes</div>
              </div>
            )}

          {permisosAdminUser.includes(state.rol) && (
            <>
              <div
                className="menu_item"
                onClick={() => {
                  history.push("/admin");
                  onClose();
                }}
              >
                <div className="p-2">
                  <IconUI>
                    <FaUsers />
                  </IconUI>
                </div>
                <div className="">Administrador de Usuarios</div>
              </div>
            </>
          )}

          {permisosPublicador.includes(state.rol) && (
            <>
              <div
                className="menu_item"
                onClick={() => {
                  history.push("/Tramites");
                  onClose();
                }}
              >
                <div className="p-2">
                  <IconUI>
                    <MdOutlineDynamicForm />
                  </IconUI>
                </div>
                <div className="">Publicador de Trámites</div>
              </div>
            </>
          )}

          {/* {permisosVentanilla.includes(state.rol) && (
            <div
              className="menu_item"
              onClick={() => {
                history.push("/cancelacion-pago");
                onClose();
              }}
            >
              <div className="p-2">
                <IconUI>
                  <FaMoneyCheckAlt />
                </IconUI>
              </div>
              <div>Cancelación de Pago</div>
            </div>
          )} */}

          {/* OPCIONES DEL MENU COMENTADAS */}
          {/* {permisosConfiguradorTramite.includes(state.rol) &&
            usuario.tieneCategorias === 1 && (
              <>
                <div
                  className="menu_item"
                  onClick={() => {
                    history.push("/configuradorTramite");
                    onClose();
                  }}
                >
                  <div className="p-2">
                    <IconUI>
                      <MdOutlineDynamicForm />
                    </IconUI>
                  </div>
                  <div className="">Configurador de Trámites</div>
                </div>
              </>
            )}

          {permisosConfGrat.includes(state.rol) && (
            <>
              <div
                className={
                  state.toggleCss ? "menu_item menu_item_active" : "menu_item"
                }
                onClick={() => {
                  // history.push('/configuradorTramiteGratuito')
                  setState({ ...state, toggleCss: !state.toggleCss });
                  // onClose()
                }}
              >
                <div className="w-100 d-flex flex-row justify-content-center align-items-center">
                  <div className="p-2">
                    <IconUI>
                      <MdOutlineDynamicForm />
                    </IconUI>
                  </div>
                  <div className="">Configurador de Trámites Gratuitos</div>
                  <div className="col"></div>
                  <div
                    onClick={() => {
                      setState({ ...state, toggleCss: !state.toggleCss });
                    }}
                    className="toggle"
                  >
                    <MdKeyboardArrowDown />
                  </div>
                </div>
              </div>
              {state.toggleCss ? (
                <div className="">
                  {!permisosRevisor.includes(state.rol) && (
                    <div className="ps-4 border-bottom">
                      <div
                        className="menu_item"
                        onClick={() => {
                          history.push("/configuradorTramiteGratuito");
                          onClose();
                        }}
                      >
                        Crear Formulario
                      </div>
                    </div>
                  )}
                  {!permisosRevisor.includes(state.rol) && (
                    <div className="ps-4 border-bottom">
                      <div
                        className="menu_item"
                        onClick={() => {
                          history.push(
                            "/configuradorTramiteGratuito_plantillas"
                          );
                          onClose();
                        }}
                      >
                        Crear Trámite
                      </div>
                    </div>
                  )}

                  {!permisosRevisor.includes(state.rol) && (
                    <div className="ps-4 border-bottom">
                      <div
                        className="menu_item"
                        onClick={() => {
                          history.push("/TramitesGratuitos");
                          onClose();
                        }}
                      >
                        Mis Trámites
                      </div>
                    </div>
                  )}

                  <div className="ps-4 border-bottom">
                    <div
                      className="menu_item"
                      onClick={() => {
                        history.push("/Turnado-Tramite");
                        onClose();
                      }}
                    >
                      Turnado
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          )} */}

          <div className="col"></div>

          <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
            <div className="icon_btn" onClick={logOut}>
              <IconUI size={30}>
                <BiLogOutCircle />
              </IconUI>
              <div className="icon_btn_help">Cerrar Sesión</div>
            </div>
            <div className="ms-3 text-vinoGob my-3">{usuario.nombre}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuComponent;
